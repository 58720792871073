import React from "react";
import { Link } from "react-router-dom";
import "./contactus.css"; // Import the CSS file for additional styling

function ContactUs() {
  return (
    <div className="container">
      <h2 className="text-center mt-4">
        Contact <span style={{ color: "#203784" }}>Us</span>
      </h2>
      <div className="row mt-5">
        <div className="col-md-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">
                <i className="fas fa-envelope"></i> Email
              </h5>
              <p className="card-text">
                <Link to="mailto:Shihorasprocesssafety@gmail.com">
                  Shihorasprocesssafety@gmail.com
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">
                <i className="fas fa-phone"></i> Phone Numbers
              </h5>
              <p className="card-text">
                <Link to="tel:+6355797121">6355797121</Link> /{" "}
                <Link to="tel:+7698353291">7698353291</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
