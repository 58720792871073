import React from "react";
import "./services.css";
import Image1 from "../assets/image17.jpg"; // Import your images (example)
import Image2 from "../assets/image18.jpg"; // Import your images (example)

function PesoWork() {
  const peso = [
    "Explosive Licence and renewal",
    "Gas cylinder licence and renewal",
    "License amendment",
  ];

  return (
    <div className="container-fluid">
      <h2 className="text-center mt-4">
        Explosive/Petroleum <span>(PESO) Work</span>
      </h2>
      <div className="container-fluid p-4 mt-4">
        <div className="row">
          <div className="col-md-12">
            <ul>
              <li>
                The Explosive/Petroleum (PESO) work encompasses stringent
                regulations and guidelines to ensure safety in the handling,
                storage, and transportation of hazardous materials.
              </li>
              <li>
                Compliance with PESO norms is crucial for industries dealing
                with explosives and petroleum products.
              </li>
              <li>
                This involves rigorous safety audits, risk assessments, and
                adherence to best practices to mitigate potential hazards.
              </li>
              <li>
                Proper training and certification for personnel are essential,
                along with the implementation of robust safety management
                systems.
              </li>
              <li>
                PESO's framework aims to minimize risks associated with
                explosive and petroleum operations, ensuring the protection of
                both personnel and the environment.
              </li>
            </ul>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="image-animation">
                  <img
                    src={Image1}
                    className="img-fluid rounded about-image"
                    alt="Chemical Engineers"
                    style={{ height: "300px", width: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="image-animation">
                  <img
                    src={Image2}
                    className="img-fluid rounded about-image"
                    alt="Chemical Engineers"
                    style={{ height: "300px", width: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <h3
          className="text-center mt-3 p-2"
          style={{ backgroundColor: "darkslategray", color: "white", fontFamily: "Arial, sans-serif" }}
        >
          PSM Specific services
        </h3>
        <div className="row mt-4 mb-4">
          {peso.map((service, index) => (
            <div className="col-md-12 mb-4" key={index}>
              <div className="box">
                <div className="box-content">
                  <h4 style={{ fontSize: "1.5rem" }}>
                    {index + 1}. {service}
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PesoWork;