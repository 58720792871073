import React, { useState, useEffect } from "react";
import "./services.css";
import Image1 from "../assets/image6.jpg";
import Image2 from "../assets/image26.jpg";
import Image3 from "../assets/image23.jpg";
import Image4 from "../assets/image25.jpg";
import Image5 from "../assets/image13.jpg";
import Image6 from "../assets/image14.jpg";

function SpecializedTrainings() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [Image1, Image2, Image3, Image4, Image5, Image6];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [images.length]);

  const trainings = [
    "Training as per Section 111-A",
    "Fire Fighting and Rescue Operation",
    "PSM elements",
    "MSDS /Chemical handling/ Manual Material Handling",
    "Scaffolding, Work Permit system",
    "Confined Space Entry",
    "Working At Height",
    "Hot work Permit",
    "Electrical Safety Management",
    "Heavy Earth Moving Machinery/Equipment Operation",
    "Pre startup Safety Review (PSSR)",
    "Fire Fighting system",
    "LOTO & TO",
    "Safety Observation and Audit (BBS)",
    "CSM-Contractor Safety management system Implementation",
    "Incident Investigation",
    "TT Driver Training",
  ];

  return (
    <div className="container-fluid">
      <h2 className="text-center mt-4 p-2">
        Specialized <span>Trainings</span>
      </h2>
      <div className="container-fluid p-4 mt-4">
        <div className="row">
          <div className="col-md-6">
            <h3
              className="text-center p-2"
              style={{ backgroundColor: "darkslategray", color: "white", fontFamily: "Arial, sans-serif" }}
            >
              Special features of Safety training
            </h3>
            <ul>
              <li>
                How to improve Awareness About Safety Requirements and Policies
                to enhance safety culture.
              </li>
              <li>How employees Becoming Accountable.</li>
              <li>How to Facilitating Involvement in safety program.</li>
              <li>Proactive Hazard Identification and Control.</li>
              <li>Incident/Accident Analysis to avoid reoccurrence.</li>
              <li>How to improve Proactive Participation.</li>
              <li>Training Metrics management.</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="image-slider">
              <img
                src={images[currentImageIndex]}
                className="img-fluid rounded about-image"
                alt={`${currentImageIndex + 1}`}
                style={{ maxHeight: "400px", width: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-4">
        <h3
          className="text-center mt-3 p-2"
          style={{ backgroundColor: "darkslategray", color: "white", fontFamily: "Arial, sans-serif" }}
        >
          Specifics
        </h3>
        <div className="row mt-4 mb-4">
          {trainings.map((training, index) => (
            <div className="col-md-12 mb-4" key={index}>
              <div className="box">
                <div className="box-content">
                  <h4 style={{ fontSize: "1.5rem" }}>
                    {String.fromCharCode(65 + index)}. {training}
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SpecializedTrainings;
