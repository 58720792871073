import React from 'react';
import { Link } from 'react-router-dom';
import './privacyPolicyAndTeam.css'; // Import your CSS file

const PrivacyPolicyAndTeam = () => {
  return (
    <div className="privacy-policy-container container-fluid mb-4">
      <div className="privacy-section">
        <h2>Privacy Policy</h2>
        <p className="policy-intro">
          At <strong>M/s Shihora’s Process Safety Consultancy</strong>, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or engage with our services.
        </p>
      </div>

      <div className="privacy-section">
        <h3>Use of Information</h3>
        <p>
          We use the information we collect to:
        </p>
        <ul>
          <li>Provide and maintain our services, including Third-Party Safety Audits and Process Safety Consulting.</li>
          <li>Communicate with you, including responding to inquiries and providing updates on services.</li>
          <li>Improve our services and develop new offerings based on customer needs.</li>
          <li>Comply with legal obligations and enforce our policies.</li>
        </ul>
      </div>

      <div className="privacy-section">
        <h3>Information Sharing</h3>
        <p>
          We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as required by law or to trusted service providers who assist us in operating our website and conducting our business.
        </p>
      </div>

      <div className="privacy-section">
        <h3>Data Security</h3>
        <p>
          We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your information.
        </p>
      </div>

      <div className="privacy-section">
        <h3>Your Consent</h3>
        <p>
          By using our website and engaging with our services, you consent to our Privacy Policy and agree to its terms.
        </p>
      </div>

      <h2>Team Introduction</h2>

      <div className="team-section">
        <h3>Our Team</h3>
        <p>
          At M/s Shihora’s Process Safety Consultancy, our team comprises highly experienced professionals dedicated to delivering excellence in safety consulting and third-party audits.
        </p>
      </div>

      <div className="team-section">
        <h3>Leadership</h3>
        <p>
           <strong>Manesh V Patel</strong> : Chemical Engineer with 30 years of industrial expertise across Argo chemical, Chemicals, Specialty chemical, and Process Safety.
        </p>
      </div>

      <div className="team-section">
        <h3>Consultants and Experts</h3>
        <p>
          Our team includes certified specialists in:
        </p>
        <ul>
          <li>Third-Party Safety Audit as per IS 14489-2018</li>
          <li>Process Safety Implementation & Gap Analysis</li>
          <li>HAZOP, SIL, LOPA, QRA</li>
          <li>Workplace Ergonomics Hazard Study</li>
          <li>Safety System Implementation</li>
          <li>BBS System Implementation</li>
          <li>Safety Training Experts</li>
        </ul>
      </div>

      <div className="team-section">
        <h3>Mission</h3>
        <p>
          Our mission is to provide quick, quality, and cost-effective safety services that protect people, preserve assets, and promote environmental sustainability.
        </p>
      </div>

      <div className="team-section">
        <h3>Contact Us</h3>
        <p>
          For inquiries or to learn more about our services, please contact us at <strong><Link to="mailto:Shihorasprocesssafety@gmail.com">Shihorasprocesssafety@gmail.com</Link> or <Link to="tel:+6355797121">6355797121</Link> / <Link to="tel:+7698353291">7698353291</Link></strong>.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicyAndTeam;
