import React, { useEffect, useState } from "react";
import "./cookieAcceptance.css"; // Import the CSS file for styles

const CookieAcceptance = ({ children , prop }) => {
  const [showCookieMessage, setShowCookieMessage] = useState(true);

  useEffect(() => {
    // Check if cookie acceptance preference is stored
    const acceptedCookies = localStorage.getItem("accept-cookies");
    if (acceptedCookies) {
      setShowCookieMessage(false); // Hide cookie message if already accepted
    } else {
      setShowCookieMessage(true); // Show cookie message if not accepted
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const acceptCookies = () => {
    localStorage.setItem("accept-cookies", true); // Store cookie acceptance preference
    setShowCookieMessage(false); // Hide the cookie message after accepting
  };

  return (
    <div className="page-container" >
      {children}
      {showCookieMessage && (
        <div className="cookie-acceptance">
          <div className="cookie-content">
            <p className="cookie-text">
              We use cookies to provide you with a better service.
              <button className="btn btn-danger" onClick={acceptCookies}>
                Accept cookies
              </button>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieAcceptance;