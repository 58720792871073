import React from "react";
import "./services.css";
import Image1 from "../assets/image21.jpg"; // Import your images (example)
import Image2 from "../assets/image22.jpg"; // Import your images (example)

function RiskAssessment() {
  const riskAssessmentServices = [
    "QRA - Quantitative Risk Assessment DNV - FAST software based",
  ];

  return (
    <div className="container-fluid">
      <h2 className="text-center mt-4">
        Risk <span>Assessment</span>
      </h2>
      <div className="container-fluid p-4 mt-4">
        <div className="row">
          <div className="col-md-12">
            <ul>
              <li>
                Risk assessment is a systematic process of evaluating potential
                risks in a workplace or environment to identify hazards, assess
                their likelihood and potential consequences, and determine
                appropriate control measures.
              </li>
              <li>
                It involves gathering data on potential hazards, analyzing risks
                based on criteria such as severity and probability, and
                prioritizing actions to mitigate or eliminate risks.
              </li>
              <li>
                Effective risk assessments help organizations understand and
                manage risks proactively, ensuring the safety of personnel,
                minimizing financial losses, and maintaining regulatory
                compliance.
              </li>
              <li>
                By regularly conducting risk assessments, organizations can
                create safer working environments and enhance overall
                operational resilience.
              </li>
            </ul>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="image-animation">
                  <img
                    src={Image1}
                    className="img-fluid rounded about-image"
                    alt="Risk Assessment 1"
                    style={{ height: "300px", width: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="image-animation">
                  <img
                    src={Image2}
                    className="img-fluid rounded about-image"
                    alt="Risk Assessment 2"
                    style={{ height: "300px", width: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <h3
          className="text-center mt-3 p-2"
          style={{ backgroundColor: "darkslategray", color: "white", fontFamily: "Arial, sans-serif" }}
        >
          Specifics
        </h3>
        <div className="row mt-4 mb-4">
          {riskAssessmentServices.map((service, index) => (
            <div className="col-md-12 mb-4" key={index}>
              <div className="box">
                <div className="box-content">
                  <h4 style={{ fontSize: "1.5rem" }}>
                    {index + 1}. {service}
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RiskAssessment;