import React from "react";
import "./about.css"; // Import the CSS file for additional styling
import Image1 from "../assets/image9.jpg"; // Import your images (example)
import Image2 from "../assets/image6.jpg"; // Import your images (example)
import Image3 from "../assets/image10.jpg"; // Import your images (example)
import Image4 from "../assets/image12.jpg"; // Import your images (example)
import Image5 from "../assets/image11.jpg"; // Import your images (example)
import Image6 from "../assets/image24.jpg"; // Import your images (example)

function About() {
  return (
    <div className="about-container">
      <div className="container-fluid">
        <h2 className="text-center mb-0">
          About <span>Us</span>
        </h2>
      </div>
      <div className="container-fluid p-5 mt-4 mb-4">
        <div className="row">
          <div className="col-md-6">
            <p>
              It's a pleasure to introduce{" "}
              <strong>"M/s Shihora’s Process Safety Consultancy"</strong> as
              Consulting Engineers of “Third-Party Safety Audit as per IS
              14489-2018”, Process safety implementation & Gap Analysis, HAZOP,
              SIL, LOPA, QRA, Workplace Ergonomics Hazard Study (Assessment),
              Safety System implementation, BBS system implementation, and
              Safety Training experts.
            </p>
            <p>
              We have experienced chemical engineers with 30 years of industrial
              involvement in Agro chemicals, Chemicals, Specialty chemicals,
              Chlor-alkali plants, Cement industries, Pharma, Food and drugs,
              Terminal port, and Process Safety expertise with more than 80
              HAZOP experiences.
            </p>
          </div>
          <div className="col-md-6">
            <img
              src={Image1}
              className="img-fluid rounded about-image"
              alt="Process Safety Consultancy"
            />
          </div>
        </div>
        <h2 className="text-center mt-4">Services</h2>
        <div className="row mt-5">
          <div className="col-md-4 mb-4">
            <div className="image-animation">
              <img
                src={Image2}
                className="img-fluid rounded about-image"
                alt="Specialized Trainings"
              />
            </div>
          </div>
          <div className="col-md-8 mb-4">
            <div className="service-description">
              <div className="box">
                <h4 className="p-3 mb-3 bg-warning text-dark rounded shadow">
                  <span className="list-number">1.</span> Specialized Trainings
                </h4>
                <p>
                  We offer specialized training programs aimed at enhancing
                  safety knowledge and compliance in various industrial sectors.
                  Our courses cover essential topics such as hazard
                  identification, emergency response, and regulatory compliance,
                  tailored to meet industry-specific needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-8 mb-4">
            <div className="service-description">
              <div className="box">
                <h4 className="p-3 mb-3 bg-warning text-dark rounded shadow">
                  <span className="list-number">2.</span> Process safety /
                  Safety Services
                </h4>
                <p>
                  Our comprehensive process safety services include HAZOP
                  (Hazard and Operability Study), SIL (Safety Integrity Level)
                  assessment, LOPA (Layer of Protection Analysis), QRA
                  (Quantitative Risk Assessment), and safety system
                  implementation. We ensure optimal safety measures are
                  integrated into industrial processes to mitigate risks
                  effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="image-animation">
              <img
                src={Image3}
                className="img-fluid rounded about-image"
                alt="Process Safety / Safety Services"
              />
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4 mb-4">
            <div className="image-animation">
              <img
                src={Image4}
                className="img-fluid rounded about-image"
                alt="Explosive/Petroleum (PESO) Work"
              />
            </div>
          </div>
          <div className="col-md-8 mb-4">
            <div className="service-description">
              <div className="box">
                <h4 className="p-3 mb-3 bg-warning text-dark rounded shadow">
                  <span className="list-number">3.</span> Explosive/Petroleum
                  (PESO) Work
                </h4>
                <p>
                  With expertise in explosive and petroleum regulations under
                  PESO (Petroleum and Explosives Safety Organization), we assist
                  clients in obtaining new licenses, handling license
                  amendments, renewals, and compliance with regulatory standards
                  for safe handling and storage practices.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-8 mb-4">
            <div className="service-description">
              <div className="box">
                <h4 className="p-3 mb-3 bg-warning text-dark rounded shadow">
                  <span className="list-number">4.</span> Third Party Safety
                  Audit
                </h4>
                <p>
                  We conduct thorough third-party safety audits as per IS
                  14489-2018 standards, focusing on evaluating safety protocols,
                  identifying gaps, and recommending improvements to enhance
                  workplace safety and regulatory compliance.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="image-animation">
              <img
                src={Image5}
                className="img-fluid rounded about-image"
                alt="Third Party Safety Audit"
              />
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4 mb-4">
            <div className="image-animation">
              <img
                src={Image6}
                className="img-fluid rounded about-image"
                alt="Risk Assessment"
              />
            </div>
          </div>
          <div className="col-md-8 mb-4">
            <div className="service-description">
              <div className="box">
                <h4 className="p-3 mb-3 bg-warning text-dark rounded shadow">
                  <span className="list-number">5.</span> Risk Assessment
                </h4>
                <p>
                  Our risk assessment services provide detailed evaluations of
                  potential hazards and risks associated with industrial
                  operations. We utilize advanced methodologies to assess risks
                  comprehensively, prioritize mitigation strategies, and enhance
                  overall safety performance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <p>
          In view of the above, we shall be highly obliged if you kindly enlist
          our name in your list of approved vendors and would be extremely
          pleased to serve you quality service on your general & regular
          enquiries.
        </p>
        <p>
          Our Motto is:{" "}
          <strong>
            Quick, Quality, and Best Safety services with minimum cost…
          </strong>
        </p>
        <p>
          <strong>
            To… Protect people, Preserve assets, and Promote environment…
          </strong>
        </p>
      </div>
    </div>
  );
}

export default About;
