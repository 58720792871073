import React from "react";
import "./services.css";
import Image1 from "../assets/image16.jpg"; // Import your images (example)
import Image2 from "../assets/image18.jpg"; // Import your images (example)

function ProcessSafety() {
  const safetyServices = [
    "PSM-Safety System gap analysis and Implementation",
    "HAZOP-Hazard and Compatibility study (As per IS- 61882- Guideword and 5 by 5 matrix Base)",
    "LOPA (Layers of Protection Analysis) study",
    "SIL (Safety Integrity Level)",
    "HAC- Hazardous Area Classification (IEC-60079-10-1 & OISD-STD-113)",
    "Electrical Safety Audits & Arc Flash study/ Energy Audit",
    "Housekeeping /5S training, implementations and evaluation.",
    "Fire Adequacy Study as per Rule-26 of SMPV (U) Rules, 2016 & OISD-117",
    "FMEA (Failure mode and Event Analysis)",
    "RCA (Root cause analysis)",
    "Preparation of ON-SITE Emergency Action Plan as per 68-J",
    "Preparation of Safety Manuals and Safety report etc.…",
    "Workplace Ergonomics Study (7 Step Method) with training",
    "Fire Hydrant Installation",
    "ISO certification and documentation",
  ];

  return (
    <div className="container-fluid">
      <h2 className="text-center mt-4">
        Process Safety <span>/ Safety Services</span>
      </h2>
      <div className="container-fluid p-4 mt-4">
        <div className="row">
          <div className="col-md-12">
            <ul>
              <li>
                Process Safety and Safety Services encompass a range of critical
                features and modules designed to ensure workplace safety and
                compliance.
              </li>
              <li>
                These services typically include comprehensive assessments
                tailored to specific site conditions and operational processes.
              </li>
              <li>
                They integrate training programs that utilize advanced
                methodologies such as in-house animation and case studies of
                real-world incidents, enhancing participant engagement and
                knowledge retention. Expert-led modules cover essential topics
                like hazard identification, emergency response procedures, and
                safety audits, ensuring a proactive approach to managing risks
                and fostering a culture of safety within industrial
                environments.
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="image-animation">
              <img
                src={Image1}
                className="img-fluid rounded about-image"
                alt="Chemical Engineers"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="image-animation">
              <img
                src={Image2}
                className="img-fluid rounded about-image"
                alt="Chemical Engineers"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-4">
        <h3
          className="text-center mt-3 p-2"
          style={{ backgroundColor: "darkslategray", color: "white", fontFamily: "Arial, sans-serif" }}
        >
          PSM Specific services
        </h3>
        <div className="row mt-4 mb-4">
          {safetyServices.map((service, index) => (
            <div className="col-md-12 mb-4" key={index}>
              <div className="box">
                <div className="box-content">
                  <h4 style={{ fontSize: "1.5rem" }}>
                    {index + 1}. {service}
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProcessSafety;