import React from 'react';
import { Link } from "react-router-dom";
import "./footer.css"; // Import the CSS file

function Footer() {
  return (
    <footer className="footer text-bg-light text-center text-lg-start">
      <div className="container text-center p-3">
        &copy; 2024 Shihora's Process Safety Consultancy. All rights reserved. <Link to="/policy/privacyPolicyAndTeam">Privacy Policy</Link>
      </div>
    </footer>
  );
}

export default Footer;