import React from "react";
import "./services.css";
import Image1 from "../assets/image19.jpg"; // Import your images (example)
import Image2 from "../assets/image11.jpg"; // Import your images (example)
import Image3 from "../assets/image20.jpg"; // Import your images (example)

function SafetyAudit() {
  const SafetyAudit = [
    "Conducting Third Party Safety Audit as per IS 14489-2018",
    "System Audit to identify effectiveness & Gap in the system",
  ];

  return (
    <div className="container-fluid">
      <h2 className="text-center mt-4">
        Third Party <span>Safety Audit</span>
      </h2>
      <div className="container-fluid p-4 mt-4">
        <div className="row">
          <div className="col-md-12">
            <ul>
              <li>
                A Third Party Safety Audit involves an independent evaluation of
                a company's safety protocols, procedures, and performance by an
                external organization.
              </li>
              <li>
                This audit aims to provide an unbiased assessment of the
                existing safety measures and identify areas for improvement.
              </li>
              <li>
                The process includes a comprehensive review of safety
                documentation, on-site inspections, and interviews with
                personnel to ensure compliance with regulatory standards and
                industry best practices.
              </li>
              <li>
                Third party audits are crucial for mitigating risks, enhancing
                workplace safety, and ensuring that safety programs are
                effective and up-to-date.
              </li>
              <li>
                By leveraging the expertise of external auditors, organizations
                can gain valuable insights into potential safety hazards and
                implement corrective actions to foster a safer working
                environment.
              </li>
            </ul>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="image-animation">
                  <img
                    src={Image1}
                    className="img-fluid rounded about-image"
                    alt="Chemical Engineers"
                    style={{ height: "300px", width: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="image-animation">
                  <img
                    src={Image2}
                    className="img-fluid rounded about-image"
                    alt="Chemical Engineers"
                    style={{ height: "300px", width: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="image-animation">
                  <img
                    src={Image3}
                    className="img-fluid rounded about-image"
                    alt="Chemical Engineers"
                    style={{ height: "300px", width: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-4">
        <h3
          className="text-center mt-3 p-2"
          style={{ backgroundColor: "darkslategray", color: "white", fontFamily: "Arial, sans-serif" }}
        >
          Specifics
        </h3>
        <div className="row mt-4 mb-4">
          {SafetyAudit.map((service, index) => (
            <div className="col-md-12 mb-4" key={index}>
              <div className="box">
                <div className="box-content">
                  <h4 style={{ fontSize: "1.5rem" }}>
                    {index + 1}. {service}
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SafetyAudit;
