import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home";
import About from "./components/about/about";
import OurTeam from "./components/teams/ourTeam";
import ContactUs from "./components/contactus/contactus";
import Navbar from "./components/header/navbar";
import PrivacyPolicyAndTeam from "./components/policy/privacyPolicyAndTeam";
import Footer from "./components/footer/footer";
import PesoWork from "./components/services/pesoWork";
import ProcessSafety from "./components/services/processSafety";
import RiskAssessment from "./components/services/riskAssessment";
import SafetyAudit from "./components/services/safetyAudit";
import SpecializedTrainings from "./components/services/specializedTrainings";
import CookieAcceptance from "./components/CookieAcceptance";

import "./App.css";

const App = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const acceptedCookies = localStorage.getItem("accept-cookies");

    if (!acceptedCookies) {
      const timeoutId = setTimeout(() => {
        setShowPopup(true);
      }, 50000); // 50000 milliseconds = 50 seconds

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/ourTeam" element={<OurTeam />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/services/pesoWork" element={<PesoWork />} />
          <Route path="/services/processSafety" element={<ProcessSafety />} />
          <Route path="/services/riskAssessment" element={<RiskAssessment />} />
          <Route path="/services/safetyAudit" element={<SafetyAudit />} />
          <Route
            path="/services/specializedTrainings"
            element={<SpecializedTrainings />}
          />
          <Route
            path="/policy/privacyPolicyAndTeam"
            element={<PrivacyPolicyAndTeam />}
          />
        </Routes>
        {showPopup && <CookieAcceptance />}
        <Footer />
      </Router>
    </div>
  );
};

export default App;
