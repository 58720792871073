import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./home.css";
import Image1 from "./assets/image1.jpg";
import Image2 from "./assets/image2.jpg";
import Image3 from "./assets/image3.jpg";
import Image4 from "./assets/image23.jpg";
import Image5 from "./assets/image5.jpg";

function Home() {
  const carouselRef = React.useRef(null);

  const setActiveImage = (index) => {
    carouselRef.current.setAttribute("data-active-index", index.toString());
  };

  return (
    <>
      {/* Carousel */}
      <div
        id="carouselExampleTouch"
        className="carousel slide"
        data-mdb-touch="false"
        ref={carouselRef}
      >
        <div className="carousel-indicators">
          {[...Array(5)].map((_, index) => (
            <button
              key={index}
              type="button"
              data-mdb-target="#carouselExampleTouch"
              data-mdb-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : undefined}
              aria-label={`Slide ${index + 1}`}
              onClick={() => setActiveImage(index)}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={Image1}
              className="d-block w-100 slide-in-top"
              alt="Wild Landscape"
            />
            <div className="carousel-caption">
              <div className="caption-content">
                <h3>Process Safety Solutions</h3>
                <p>
                  Permit to Work, Accident / Incident / Near-Miss Analysis,
                  Training Management, Job Hazard Analysis, Environment Data
                  Monitoring & Reporting and analysis to beaded, JOB Hazard
                  analysis – Job safety analysis (JSA), Legal Compliance / Legal
                  Document Management and Many More...
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Image2}
              className="d-block w-100 slide-in-top"
              alt="Camera"
            />
            <div className="carousel-caption">
              <div className="caption-content">
                <h3>Specialized Trainings</h3>
                <p>
                  We offer specialized training to enhance safety knowledge in
                  various industries. Our courses cover hazard identification,
                  emergency response, and regulatory compliance, tailored to
                  industry-specific needs.
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Image3}
              className="d-block w-100 slide-in-top"
              alt="Exotic Fruits"
            />
            <div className="carousel-caption">
              <div className="caption-content">
                <h3>Petroleum (PESO) Work</h3>
                <p>
                  With expertise in PESO regulations, we assist clients in
                  obtaining new licenses, handling amendments, renewals, and
                  ensuring compliance with safety standards for handling and
                  storage practices.
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Image4}
              className="d-block w-100 slide-in-top"
              alt="Fourth slide"
            />
            <div className="carousel-caption">
              <div className="caption-content">
                <h3>Third Party Safety Audit</h3>
                <p>
                  Thorough third-party safety audits per IS 14489-2018,
                  evaluating safety protocols, identifying gaps, and
                  recommending improvements for workplace safety and regulatory
                  compliance.
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={Image5}
              className="d-block w-100 slide-in-top"
              alt="Fifth slide"
            />
            <div className="carousel-caption">
              <div className="caption-content">
                <h3>Risk Assessment</h3>
                <p>
                  Detailed hazard evaluations, Comprehensive risk assessments,
                  Prioritize mitigation strategies, and Enhance safety
                  performance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-mdb-target="#carouselExampleTouch"
          data-mdb-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-mdb-target="#carouselExampleTouch"
          data-mdb-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* Our Services Section */}
      <div className="container-fluid mt-4">
        <h2 className="text-center mb-4">
          Our <span>Services</span>
        </h2>
        <div className="row row-cols-1 row-cols-md-3 g-3 mb-4">
          <div className="col">
            <div className="card text-bg-light h-100">
              <div className="card-body">
                <h5 className="card-title">Specialized Trainings</h5>
                <p className="card-text">
                  Tailored training programs, Compliance-focused modules,
                  Industry-specific workshops
                </p>
                <Link
                  to="/services/specializedTrainings"
                  className="btn btn-primary"
                >
                  Know More
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card text-bg-light h-100">
              <div className="card-body">
                <h5 className="card-title">Process Safety / Safety Services</h5>
                <p className="card-text">
                  Comprehensive safety assessments, Risk management strategies,
                  Safety system implementations
                </p>
                <Link to="/services/processSafety" className="btn btn-primary">
                  Know More
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card text-bg-light h-100">
              <div className="card-body">
                <h5 className="card-title">Explosive/Petroleum (PESO) Work</h5>
                <p className="card-text">
                  Regulatory compliance services, Safety audits and inspections,
                  Hazardous materials handling expertise
                </p>
                <Link to="/services/pesoWork" className="btn btn-primary">
                  Know More
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card text-bg-light h-100">
              <div className="card-body">
                <h5 className="card-title">Third Party Safety Audit</h5>
                <p className="card-text">
                  Independent safety evaluations, Compliance verification Audit,
                  reports and recommendations
                </p>
                <Link to="/services/safetyAudit" className="btn btn-primary">
                  Know More
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card text-bg-light h-100">
              <div className="card-body">
                <h5 className="card-title">Risk Assessment</h5>
                <p className="card-text">
                  Hazard identification and analysis, Risk quantification,
                  Mitigation strategies
                </p>
                <Link to="/services/riskAssessment" className="btn btn-primary">
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
