import React from "react";
import "./ourTeam.css";
import Image1 from "../assets/person1.jpg";
import Image2 from "../assets/person2.jpg";
import Image3 from "../assets/person3.jpg";
import Image4 from "../assets/person4.jpg";
import Image5 from "../assets/person5.jpg";
import Image6 from "../assets/person6.jpg";

const OurTeam = () => {
  const teamMembers = [
    {
      name: "Manesh V Patel",
      position: "CEO & Founder",
      description:
        "A seasoned EHS professional with 27 years of experience in Chemical and related industries, specializing in HAZOP, SIL Training, and regulatory compliance.",
      image: Image1,
    },
    {
      name: "Rutanshi Patel",
      position: "Marketing and Sales Specialist",
      description:
        "Dedicated marketing and sales specialist leveraging social media and mobile marketing to drive brand growth and engagement.",
      image: Image4,
    },
    {
      name: "ER. Rumit Jasani",
      position: "Structural Engineer",
      description:
        "Accomplished structural engineer with 5+ years of expertise in seismic design, sustainable construction, and mentoring young engineers.",
      image: Image3,
    },
    {
      name: "Deepak Ghagare",
      position: "SR SAFETY/HSE PROFESSIONAL",
      description:
        "Senior HSE professional with over 30 years of extensive experience in leading multinational groups such as Saudi Aramco, SABIC, and Reliance, specializing in establishing and maintaining Safety Management Systems to international standards for continuous improvement in HSE practices.",
      image: Image5,
    },
    {
      name: "Mitul Shihora",
      position: "Electrical Engineer",
      description:
        "Expert in electrical and energy audit, Electrical installation and technical support.",
      image: Image2,
    },
    {
      name: "Piyush Shihora",
      position: "Chartered Accountant",
      description:
        "A seasoned finance leader with 4+ years in Taxation, Financial Management, and Accounting. Renowned for his exceptional leadership, business partnering, and compliance skills. His transformative leadership and multitasking abilities have driven numerous organizational successes.",
      image: Image6,
    },
  ];

  return (
    <div className="container-fluid p-4">
      <div className="section-title">
        <h2>
          Our <span>Team</span>
        </h2>
      </div>
      <div className="row mt-4 mb-4 d-flex justify-content-center">
        {teamMembers.map((member, index) => (
          <div className="col-12 col-md-6 col-lg-4 mb-4" key={index}>
            <div className="card rounded-3 text-bg-light h-100">
              <div className="card-body p-3">
                <div className="d-flex flex-column align-items-center">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="img-fluid mb-2 rounded"
                    style={{ width: "100%", height: "250px", objectFit: "cover" }}
                  />
                  <h4 className="mb-1 text-center">{member.name}</h4>
                  <p className="mb-2 pb-1 mt-3 text-center p-2 bg-warning bg-gradient text-dark rounded shadow">{member.position}</p>
                  <div className="rounded-3 p-2 mb-2 bg-body-tertiary text-center">
                    <p className="mb-0">{member.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;