import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";
import "./navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-body-tertiary">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src={Logo}
            alt="Logo"
            style={{ maxWidth: "600px", maxHeight: "75px" }}
          />
        </Link>
        <button
          className="navbar-toggler ps-0"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarExample01"
          aria-controls="navbarExample01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="d-flex justify-content-start align-items-center"><i className="fas fa-bars"></i></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarExample01">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link px-3" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle px-3"
                to="#"
                id="servicesDropdown"
                role="button"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </Link>
              <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                <li>
                  <Link className="dropdown-item" to="/services/specializedTrainings">
                    Specialized Trainings
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/services/processSafety">
                    Process Safety / Safety Services
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/services/pesoWork">
                    Explosive/Petroleum (PESO) Work
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/services/safetyAudit">
                    Third Party Safety Audit
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/services/riskAssessment">
                    Risk Assessment
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link px-3" to="/ourTeam">
                Team
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link px-3" to="/contactus">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
